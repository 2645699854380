import React,{memo} from 'react';
import Styles from './styles.module.css'

let Title = () => {
    let { title } = Styles;
    return (
        <section className='row justify-content-center align-items-center py-4' id={title}>
            <article className='col col-md-12 col-lg-7 text-center py-4'>
                <h1>Contáctenos</h1>
            </article>
        </section>
    )
};

export default memo(Title);