import Layout from '../src/layout/Layout';
import AsociarsePage from './pages/AsociarsePage';
import HomePage from '../src/pages/HomePage';
import NosotrosPage from './pages/NosotrosPage';
import ProductosSerciciosPage from './pages/ProductosServiciosPage';
import ProductoPage from './pages/ProductoPage';
import ContactosPage from './pages/ContactosPage';
import NoticiasPage from './pages/NoticiasPage';
import NoticiaPage from './pages/NoticiaPage';
import ErrorPage from './pages/ErrorPage';
import './App.css';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout/>,
    errorElement: <ErrorPage/>,
    children: [
      {
        errorElement: <ErrorPage />,
        children:[
       {
          index: true,
          element: <HomePage/>
        },
        {
          path:"nosotros",
          element: <NosotrosPage/>
        },
        {
          path:"productos-y-servicios",
          element: <ProductosSerciciosPage/>
        },
        {
          path:"producto/:id/:slug",
          element: <ProductoPage/>
        },
        {
          path:"contactos",
          element: <ContactosPage/>
        },
        {
          path: "asociarse",
          element:<AsociarsePage/>
        },
        {
          path :"noticias/:id/:slug",
          element: <NoticiaPage/>
        },
        {
          path: "noticias",
          element:<NoticiasPage/>
        },
      ]
      }
    ]
  },
]);

function App() {
  return (
      <RouterProvider router={router}/>
  );
}

export default App;
