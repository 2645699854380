import AsyncStorage from '@react-native-community/async-storage';
import {createStore, applyMiddleware, combineReducers, compose} from 'redux';
import {createLogger} from 'redux-logger';
import {persistStore, persistReducer} from 'redux-persist';
import thunk from 'redux-thunk';
import newsReducer from './redux/noticiasDucks';

const appReducer = combineReducers({
    newsReducer,
});

const rootReducer = (state, action) => {
    if (action.type === 'LOG_OUT') {
      state = undefined;
    }
    return appReducer(state, action);
  };

  const persistConfig = {
    // Root
    key: 'root',
    storage: AsyncStorage,
    whitelist: ['newsReducer',],
    blacklist: [],
    timeout: null,
  };

  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const store = createStore(
    persistedReducer,
    applyMiddleware(createLogger(), thunk),
  );

let persistor = persistStore(store);
// Exports
export {store, persistor};