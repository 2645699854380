import React,{useEffect, useState, useRef} from 'react';
import Slider from '../../../assets/images/home/products_slider/slider.jpg';
import Style from './styles.module.css';

let ImageBanner = ({content, imagen, title}) => {

    let {banner} = Style;
    return(
        <section className="row justify-content-center align-items-center mb-5 pb-5 ">
            <article className="col-12 col-md-10 d-flex align-items-center flex-column " style={{position:"relative"}}>
                <img src={Slider} className={`${banner} img-fluid w-100`} alt={title} data-overlay = " " />
                <div style={{position:"absolute", bottom: "0", left:"12px", right:"12px", backgroundColor:"rgba(0,0,0, .5)", padding:"20px 50px"}} >
                    {/* <img src={imagen} alt={title} id={logoimagen} /> */}
                    <h3 style={{color:"#FFF", fontWeight:900}}>{title}</h3>
                    <p style={{color:"#FFF", fontWeight:700}}>{content}</p>
                </div>
            </article>
        </section>
    )

}

export default ImageBanner;