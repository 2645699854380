let initialStates = {
    loadingNews:false,
    loadingAllNews: false,
    loadingNewsDetails : false,
    news:[],
    allNews: [],
    newsDetails: {}
};

let LOADING_NEWS = 'LOADING_NEWS';
let LOADING_ALL_NEWS = 'LOADING_ALL_NEWS';
let NEWS = 'NEWS';
let ALL_NEWS = 'ALL_NEWS';
let NEWS_DETAILS = 'NEWS_DETAILS';
let LOADING_NEWS_DETAILS = 'LOADING_NEWS_DETAILS';

export default function reducer(state = initialStates, action){
    switch(action.type){
        case LOADING_NEWS:
            return {
                ...state,
                loadingNews: action.payload
            };
        case LOADING_ALL_NEWS:
            return {
                ...state,
                loadingAllNews: action.payload
            };
        case LOADING_NEWS_DETAILS:
            return {
                ...state,
                loadingNewsDetails: action.payload
            };
        case NEWS:
            return {
                ...state,
                news: action.payload
        };
        case NEWS_DETAILS:
            return {
                ...state,
                newsDetails: action.payload
        };
        case ALL_NEWS:
            return {
                ...state,
                allNews: action.payload
        };
        default:
            return state
        
    }
}


export let loadHomeNews = () =>(dispatch, getState) => { 
    dispatch({
        type: LOADING_NEWS,
        payload: true
    });
    fetch('https://coopinmobilia.com/dashboard/wp-json/wp/v2/noticias?status=publish&per_page=4')
        .then((response) => response.json())
        .then(async(data)  => {

            dispatch({
                type: NEWS,
                payload: data
            });
            dispatch({
                    type: LOADING_NEWS,
                    payload: false
            });
            localStorage.setItem('home_news', JSON.stringify(data));
        })
        .catch((err) => {
            console.log(err.message);
            dispatch({
                type: LOADING_NEWS,
                payload: false
            });
        });
};

export let allNews = () =>(dispatch, getState) => { 
    dispatch({type:'LOG_OUT'});
    dispatch({
        type: LOADING_ALL_NEWS,
        payload: true
    });
    let url = `https://coopinmobilia.com/dashboard/wp-json/wp/v2/noticias`;
    fetch(url)
        .then((response) => response.json())
        .then(async(data)  => {
            
            dispatch({
                type: ALL_NEWS,
                payload: data
            });
            dispatch({
                type: LOADING_ALL_NEWS,
                payload: false
            });
            localStorage.setItem('all_news', JSON.stringify(data));
            
        })
        .catch((err) => {
            console.log('error request: ',err.message);
            dispatch({
                type: LOADING_ALL_NEWS,
                payload: false
            });
        });
};

export let detailsNews = id => (dispatch, getState) => {
    console.log('id', id);
    dispatch({
        type: LOADING_NEWS_DETAILS,
        payload: true
    });
    let url = `https://coopinmobilia.com/dashboard/wp-json/wp/v2/noticias/${id}?status=publish`;
    fetch(url)
        .then((response) => response.json())
        .then(async(data)  => {

            dispatch({
                type: NEWS_DETAILS,
                payload: data
            });
            dispatch({
                type: LOADING_NEWS_DETAILS,
                payload: false
            });
            console.log('news details:',data);
        })
        .catch((err) => {
            console.log(err.message);
            dispatch({
                type: LOADING_NEWS_DETAILS,
                payload: false
            });
        });
};