import React, {memo} from 'react';
import Shape from '../../../assets/images/nosotros/Organization_Chart.png';
import organization_bg from '../../../assets/images/nosotros/organization_bg.jpg';
import Styles from './styles.module.css'

let EstructuraOrganizacional = () => {
    let {organizacion} = Styles;
    return(
        <section className='row justify-content-center' style={{backgroundImage:`url(${organization_bg})`}} id={organizacion}>
            <article className='col-12 p-5 d-flex justify-content-center'>
                <img src={Shape} alt='' className='img-fluid w-75 my-3'/>
            </article>
        </section>
    )
};

export default memo(EstructuraOrganizacional);