import React,{memo} from  'react';
import AfiliarseAbout from '../../../assets/images/home/asociarse_bg.jpg';
import styles from './styles.module.css';

let AfiliarseHome = () =>{
    let {afiliarse_section, afiliarse_sectionInner, button, whiteButton} = styles;
    return(
        <section className='row' id={afiliarse_section} style={{backgroundImage:`url(${AfiliarseAbout})`}}>
            <article className='col-12'>
                <div className='row d-flex h-100'>
                    <div className='col-12'>
                        <div className={`${afiliarse_sectionInner} justify-content-center d-flex flex-column h-100`}>
                            <div className='row justify-content-center'>
                                <div className='col-12 col-md-11 col-lg-9 justify-content-center text-center'>
                                    <h5 className='mb-5'>Juntos por el desarrollo de un futuro económico sostenible.</h5>
                                    {/* <a href='/' className={[button,whiteButton].join(' ')}>Saber Más</a> */}
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                
            </article>
        </section>
    );
};

export default memo(AfiliarseHome);