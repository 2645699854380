import React,{memo} from 'react';
import Styles from './styles.module.css'

let Title = () => {
    let { title } = Styles;
    return (
        <section className='row justify-content-center align-items-center pb-5' id={title}>
            <article className='col-12 col-lg-7 text-center py-5'>
                <h1>Formulario de inscripción</h1>
                <p>Debida Diligencia Persona Física Natural (UPLA/FT-01)</p>
            </article>
        </section>
    )
};

export default memo(Title);