import React,{memo, useState, useEffect, useRef} from 'react';
//import {decodeEntities} from "@wordpress/html-entities";
import Spinner from 'react-bootstrap/Spinner';
//import parse from "html-react-parser";
import Styles from './styles.module.css';
import BG from '../../../assets/images/productos_servicios/bg.jpg';
// import Personales from '../../../assets/images/productos_servicios/personales_icon.png';
// import Comerciales from '../../../assets/images/productos_servicios/comerciales_icon.png';
// import Hipotecario from '../../../assets/images/productos_servicios/hipotecario_icon.png';

let Creditos = () => {
    let {creditos, inner, subtitle} = Styles;
    let [servicios, setServicios] = useState([]);
    let [loading, setLoading] = useState(false);
    let query = useRef(true);

    useEffect(()=>{
        const productos = JSON.parse(localStorage.getItem('productos'));
        const url= "https://coopinmobilia.com/dashboard/wp-json/wp/v2/productos-servicios/?_embed&per_page=100&status=publish";
        if(query.current && productos === null){
            query.current = false;
            setLoading(true);
            fetch(url)
            .then((response) => response.json())
            .then(async(data)  => {
                localStorage.setItem('productos', JSON.stringify(data));
                setServicios(data);
                setLoading(false);
            })
            .catch((err) => {
                //console.log(err.message);
                setLoading(false);
            });
        }else{
            setServicios(productos);
            setLoading(false);
            const interval = setInterval(() => {
                fetch(url)
                .then((response) => response.json())
                .then(async(data)  => {
                    localStorage.removeItem('productos');
                    localStorage.setItem('productos', JSON.stringify(data));
                    setServicios(data);   
                })
                .catch((err) => {
                    console.log(err.message);
                
                });
            }, 30000);
            return () => clearInterval(interval);
        }
    },[]);
    return(
        <section className="row justify-content-center align-items-center py-5"  style={{backgroundImage:`url(${BG})`}} id={creditos}>
            <div className="col-12 col-md-10">
                <article className="row justify-content-center align-items-center">
                    <div className="col-12 col-md-8 text-center mb-4">
                        <h2>Créditos</h2>
                        <p className={subtitle}>Te apoyamos con esa casa, ese negocios o eso que tanto deseas ahora, para ti y tu familia.</p>
                    </div>
                </article>
                <article className="row  justify-content-center align-items-center">
                    
                    {loading ? (
                            <div className='col-12 col-md-5 text-center pb-5 mb-5'>
                                <Spinner animation="border" role="status" variant="success">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </div>
                        ):
                
                    servicios.map(servicio => {
                        return(
                            servicio.acf.tipo_de_producto  === 'credito' ?
                                <div className="col-12 col-md-4 mb-4" key={servicio.id}> 
                                    <div className={`${inner} py-5 px-4 h-100 d-flex flex-column justify-content-center align-items-center`}>
                                        <a href={`/producto/${servicio.id}/${servicio.slug}`} style={{textDecoration:'none'}}>
                                            <img src={servicio.yoast_head_json.og_image[0].url} alt='Ahorro a la vista' className="img-fluid mb-2" />
                                            <h3>{servicio.acf.nombre_del_producto}</h3>
                                            <p>{servicio.acf.descripcion_corta_del_producto}</p>
                                        </a>
                                    </div>
                                </div>
                            : 
                            null
                           )
                    })
                }
                </article>
            </div>
        </section>
    )
};

export default memo(Creditos);