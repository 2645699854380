import React,{useState, useEffect, useRef} from 'react';
//import Slider from '../../../assets/images/home/products_slider/slider.jpg';
import styles from './styles.module.css';
import Spinner from 'react-bootstrap/Spinner';
import placeholder from './slider.jpg';

let ProductosServiciosSlider = () => {
    let {servicesCarousel, button, greenButton, servicesCaption, activeSlider} = styles;
    let [sliders, setSliders] = useState([]);
    let [loading, setLoading] = useState(false);
    let query = useRef(true);

    useEffect(()=>{

        const productosSlider = JSON.parse(localStorage.getItem('productosSlider'));
        let url = "https://coopinmobilia.com/dashboard/wp-json/wp/v2/productos-servicios";
        if(query.current && productosSlider === null){
            query.current = false;
            setLoading(true);
            fetch(url)
            .then((response) => response.json())
            .then(async(data)  => {
                let size = data.length;
                let i = 0;
                data.forEach(slider => {
                    let slider_info = {};
                    slider_info["title"] = slider.acf.nombre_del_producto;
                    slider_info["short_description"] = slider.acf.descripcion_corta_del_producto;
                    slider_info["long_description"] = slider.acf.descripcion_completa_del_producto;

                    let getData =  async() =>{
                        fetch(`https://coopinmobilia.com/dashboard/wp-json/wp/v2/media/${slider.acf.imagen}`)
                        .then(response => response.json())
                        .then( img => {
                            i++;
                            slider_info["slider"] = img.guid.rendered;
                            
                            // eslint-disable-next-line no-unused-expressions
                            i === size ? 
                                (setLoading(false),setSliders(sliders),localStorage.setItem('productosSlider', JSON.stringify(sliders))) :setLoading(true);
                        })
                        .catch((err) => {
                            console.log(err.message);
                        });
                    }
                    
                    getData().catch(console.error);
                    
                    sliders.push(slider_info);
                
                });  
            })
            .catch((err) => {
                console.log(err.message);
                setLoading(false);
            });
            
        }else{
            setSliders(productosSlider);
        }
    },[]);

    let renderSliders = (objects) => {
        return(
            <div id="services_Carousel" className={`${servicesCarousel} carousel slide`} data-bs-ride="carousel">
                <div className="carousel-indicators">
                    {objects.map((slider, index) => {
                        return <button key={index} type="button" data-bs-target="#services_Carousel" data-bs-slide-to={index} className={index === 0 ? "active" : ""} aria-current="true" aria-label={slider.title}></button>
                    })}
                </div>
                <div className="carousel-inner">
                    {objects.map((slider, index) =>{
                        return(
                            <div className={`${index === 0 ? "active" : ""} carousel-item`} key={index}>
                                <div style={{backgroundImage:`url(${slider.slider ? slider.slider : placeholder})`, objectFit:'cover', backgroundRepeat: 'no-repeat',backgroundSize: 'cover'}} className={`w-100 d-block ${activeSlider}`}>
                                    {/* <img src={slider.slider ? slider.slider : placeholder} alt={slider.title} className="d-block w-100" loading="lazy" width={1000} height={541} /> */}
                                    <div className={`${servicesCaption} carousel-caption d-none d-md-block`}>  
                                        <h5 className='d-flex'>{slider.title}</h5>
                                        <p className='d-block'>{slider.short_description}</p>
                                        <a href="/productos-y-servicios" className={[button,greenButton].join(' ')}>Saber más</a>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#services_Carousel" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#services_Carousel" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        )
    }

    return(
        <section className={`row justify-content-center align-items-center pb-5 ${servicesCarousel}`} id="services_carousel">
            <article className="col-12">
                <div className='row justify-content-center align-items-center'>
                    <div className='col-12 col-lg-10 col-md-12'>
                        {loading? (
                            <div className='row justify-content-center align-items-center'>
                                <div className='col-12 col-md-5 text-center pb-5 mb-5'>
                                    <Spinner animation="border" role="status" variant="success">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                </div>
                            </div>
                            ):

                            renderSliders(sliders)
                        }
                    </div>
                </div>
            </article>
        </section>
    )
};

export default ProductosServiciosSlider;