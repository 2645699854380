import React,{memo} from 'react';

let GMap = () => {
    return(
        <section className='row mt-3 pt-4 mt-md-0 pt-md-0'>
            <article className='col-12 mt-5 pt-0 mt-md-0 pt-md-0 gx-xl-0'>
                <div className="ratio ratio-16x9">
                    <iframe title={'Location'} src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15137.0700604364!2d-69.9655439!3d18.4715379!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x26e7e334b1346e7f!2sKW%20Capital!5e0!3m2!1sen!2sdo!4v1676748772497!5m2!1sen!2sdo" allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" style={{border:0}}></iframe>
                </div>
            </article>
        </section>
    )
};

export default memo(GMap);