import React,{memo} from 'react';
// import Slider from '../../../assets/images/home/slider/slider.jpg';
import slider from './slider.jpg';
import styles from './styles.module.css';

let PrimarySlider = () => {

    let {primarySlider, sliderCaption,sliderTitle, sliderSubTitle, button, greenButton} = styles;
   
    return(
        <section className="row" id={primarySlider}>
            <article className="col-12 gx-0">
                <div id="primaryCarousel" className="carousel slide">
                    <div className="carousel-indicators">
                        <button type="button" data-bs-target="#primaryCarousel" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        {/* <button type="button" data-bs-target="#primaryCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#primaryCarousel" data-bs-slide-to="2" aria-label="Slide 3"></button> */}
                    </div>
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <div style={{backgroundImage:`url(${slider})`, backgroundRepeat: 'no-repeat',backgroundSize: 'cover',backgroundPosition: 'bottom center',}} className='h-100 w-100 d-block'>
                                {/* <img src={slider} alt="Slider" className="d-block w-100"/> */}
                                <div className={`${sliderCaption} carousel-caption d-none d-md-block`}>
                                    <h5 className={sliderTitle}>Desarrollo económico sostenible</h5>
                                    <p className={sliderSubTitle}>Es nuestro compromiso</p>
                                    {/* <a href="/" className={[button, greenButton].join(' ')}>Saber más</a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#primaryCarousel" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Anterior</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#primaryCarousel" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Siguiente</span>
                    </button>
                </div>
            </article>
        </section>
    )
};

export default memo(PrimarySlider);