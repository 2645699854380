import React,{memo, useState, useRef} from 'react';
import emailjs from '@emailjs/browser';
//import { Formik, Form, Field } from 'formik';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Styles from './styles.module.css';
import { productos } from "./productos_servicios";

const validation = yup.object().shape({
    nombres: yup.string().required().label('Nombre(s)'),
    apellidos: yup.string().required(),
    lugarDeNacimiento: yup.string().required().label('Lugar de nacimiento'),
    sexo: yup.string().required().oneOf(["Femenino", "Masculino"]).label("Sexo"),
    IDType:yup.string().required().oneOf(["Cédula", "Pasaporte"]).label("Tipo de ID"),
    edad: yup.number().positive().integer().required(),
    idField: yup.string().required().label('No.'),
    nacionalidadOrigen: yup.string().required().label('Nacionalidad origen'),
    nacionalidadAdquirida: yup.string().required().label('Nacionalidad adquirida'),
    estadoCivil:yup.string().required().oneOf(["Si","No"]).label("¿Casado?"),
    hijos:yup.string().required().oneOf(["Si","No"]).label("¿Hijos?"),
    hijosCantidad: yup.number().integer().moreThan(0).transform((_, val) => (val !== "" ? Number(val) : null)).when('hijos',{
        is: (hijos) => hijos === 'Si',
        then: (validation) => validation.required(),
        otherwise:(validation) => validation.nullable(true).notRequired()
    }).label('Cantidad de hijos'),
    pep: yup.string().required().oneOf(["Si","No"]).label("Es un PEP's?"),
    cargopep: yup.string().when('pep',{
        is: (pep) => pep === 'Si',
        then: (validation) => validation.required(),
        otherwise:(validation) => validation.notRequired()
    }).label('Cargo PEP'),
    domicilioactual: yup.string().required(),
    celulares: yup.string().required().label('Celular(es)'),
    telefonos: yup.string().required().label('Teléfon(os)'),
    email: yup.string().email().required().label('Email'),
    actividadEconomica: yup.string().required().label('Actividad Económica'),
    ocupacion: yup.string().required().label('Ocupación'),
    lugardetrabajo: yup.string().required().label('Lugar donde trabaja'),
    telefonotrabajo:yup.string().required().label('Teléfono donde trabaja'),
    productos:yup.array().min(1,'Debe seleccionar por lo menos 1 producto').of(yup.string().required()).required().label('Productos'),
    formadepositos: yup.array().min(1,'Debe seleccionar por lo menos 1 Forma de depósito').of(yup.string().required()).required().label('Forma de Depósito'),
    utilizarcuenta: yup.string().oneOf(["Si","No"]).required(),
    mancomunada: yup.string().oneOf(["Si","No"]).required(),
    proposito: yup.string().required(),
    depositoInicial: yup.number().required(),
    ingresos: yup.number().positive().required(),
    promedio: yup.number().positive().required(),
    terms: yup.boolean().oneOf([true], 'Must Accept Terms and Conditions'),
});

let FormComponent = () => {
    let {asociarse, inner, selectProductos, relacionComercial, acuerdo, productos_to_select} = Styles;
    let [productosArray, setProductosArray] = useState([]);
    let [formasDepositosArray, setFormasDepositosArray] = useState([]);

    let form = useRef();
    //const optionsForm = { resolver: yupResolver(validation) };
    //const { register, handleSubmit, reset, formState } = useForm(optionsForm);
    //const { errors } = formState;
    const { register, handleSubmit, watch, formState: { errors } } = useForm(
        {
            resolver: yupResolver(validation),
            defaultValues:{ 
                formadepositos:[],
                productos:[]
            }
        });
    const onSubmit = data => console.log(data);

    //console.log('watch nombres:',watch("nombres"));

    let formas_depositos = [
        {
            "name" : "Efectivo",
            "value": "Efectivo"
        },
        {
            "name" : "Transferencia",
            "value": "Transferencia"
        },
        {
            "name" : "Cheque",
            "value": "Cheque"
        },
    ];
    const [checkedFormaState, setCheckedFormaState] = useState(
        new Array(formas_depositos.length).fill(false)
    );

    const [checkedState, setCheckedState] = useState(
        new Array(productos.length).fill(false)
    );

    const handleOnChangeForma = (position, name) => {
        const updatedCheckedFormaState = checkedFormaState.map((item, index) =>
          index === position ? !item : item
        );
    
        setCheckedFormaState(updatedCheckedFormaState);
        console.log(updatedCheckedFormaState);
        handleFormas(name);
    }
    let handleFormas = async name => {
        console.log('name:', name);
    
        formasDepositosArray.includes(name)
          ? formasDepositosArray.splice(formasDepositosArray.indexOf(name), 1)
          : formasDepositosArray.push(name);
    
        console.log(formasDepositosArray.length);
        console.log(formasDepositosArray);
      };

    const handleOnChange = (position, name) => {
        const updatedCheckedState = checkedState.map((item, index) =>
          index === position ? !item : item
        );
    
        setCheckedState(updatedCheckedState);
        console.log(updatedCheckedState);
        handleAmenidades(name);
    }

    let handleAmenidades = async name => {
        console.log('name:', name);
    
        productosArray.includes(name)
          ? productosArray.splice(productosArray.indexOf(name), 1)
          : productosArray.push(name);
    
        console.log(productosArray.length);
        console.log(productosArray);
      };
    // let sendEmail = (e) => {
    //     e.preventDefault();
    
    //     emailjs.sendForm('service_zz3uzar', 'template_jmjr5yq', form.current, 'RNnfTJLhURFgPkZk3')
    //       .then((result) => {
    //           console.log(result.text);
    //           alert('Mensaje enviado con exito!');
    //       }, (error) => {
    //           console.log(error.text);
    //       });
    // };

    let onCbFormSubmit = (data, e) => {
        console.log(JSON.stringify(data, null, 4));
        emailjs.sendForm('service_5ytmt4a', 'template_wnjajzz', form.current, 'PrEXC8DDQlCEIjGea')
          .then((result) => {
              console.log(result.text);
              alert('Mensaje enviado con exito!');
          }, (error) => {
              console.log(error.text);
          });
        return false
    }
    
    // console.log(errors, "errors");
    // console.log(watch(), 'formadepositos');
    return(
        <form onSubmit={handleSubmit(onCbFormSubmit)} ref={form}>
            <input type="hidden" name="products_list_checked" value={productosArray} />
            <input type="hidden" name="formas_depositos_list_checked" value={formasDepositosArray} />
            <section className='row justify-content-center align-items-center pb-5' id={asociarse}>
                <article className='col-12 col-md-10'>
                    <div className='row'>
                        <div className='col-12 col-md-6'>
                            <div className={`${inner} p-4`}>
                                <h2 className='mb-4'>Datos Generales del socio</h2>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className="mb-3">
                                            <input type="text" {...register("nombres")} className={`form-control border border-top-0 border-end-0 border-start-0 rounded-0 ${errors.nombres ? 'is-invalid' : ''}`} id="nombres" name="nombres" placeholder="Nombre(s)"/>
                                        </div>
                                        {/* {errors.nombres && 
                                            <div className="invalid-feedback d-block" style={{display:'block !important'}}>{errors.nombres?.message}</div>
                                        }
                                         */}
                                    </div>
                                    <div className='col-12'>
                                        <div className="mb-3">
                                            <input type="text" {...register("apellidos")} className={`form-control border border-top-0 border-end-0 border-start-0 rounded-0 ${errors.apellidos ? 'is-invalid' : ''}`} id="apellidos" name="apellidos" placeholder="Apellido(s)"/>
                                        </div>
                                        {/* {errors.apellidos &&
                                            <div className="invalid-feedback d-block" >{errors.apellidos?.message}</div>
                                        } */}
                                    </div>
                                    <div className='col-12 col-md-6'>
                                        <div className="mb-3">
                                            <input type="text" className="form-control border border-top-0 border-end-0 border-start-0 rounded-0" id="apodo" name="apodo" placeholder="Apodo"/>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6'>
                                        <div className="mb-3">
                                            <select  id="sexo" {...register("sexo")} className={`form-select border border-top-0 border-end-0 border-start-0 rounded-0 ${errors.sexo ? 'is-invalid' : ''}`} name="sexo" aria-label="Sexo">
                                                <option defaultValue=''>Sexo</option>
                                                <option defaultValue="Masculino">Masculino</option>
                                                <option defaultValue="Femenino">Femenino</option>
                                            </select>
                                        </div>
                                        {/* {errors.sexo &&
                                            <div className="invalid-feedback d-block">{errors.sexo?.message}</div>
                                        } */}
                                    </div>
                                    <div className='col-12 col-md-6'>
                                        <div className="mb-3">
                                            <select id="tipoID" {...register("IDType")} className={`form-select border border-top-0 border-end-0 border-start-0 rounded-0 ${errors.IDType ? 'is-invalid' : ''}`} name="IDType" aria-label="Tipo de ID">
                                                <option defaultValue=''>Tipo de ID</option>
                                                <option defaultValue="Cédula">Cédula</option>
                                                <option defaultValue="Pasaporte">Pasaporte</option>
                                            </select>
                                        </div>
                                        {/* {errors.IDType &&
                                            <div className="invalid-feedback d-block">{errors.IDType?.message}</div>
                                        } */}
                                    </div>
                                    <div className='col-12 col-md-6'>
                                        <div className="mb-3">
                                            <input type="text" {...register("idField")} className={`form-control border border-top-0 border-end-0 border-start-0 rounded-0  ${errors.idField ? 'is-invalid' : ''}`} id="idField" name="idField" placeholder="No."/>
                                        </div>
                                        {/* {errors.idField &&
                                            <div className="invalid-feedback d-block">{errors.idField?.message}</div>
                                        } */}
                                    </div>
                                    <div className='col-12 col-md-6'>
                                        <div className="mb-3">
                                            <input placeholder='Fecha de nacimiento'  type="date" className="form-control border border-top-0 border-end-0 border-start-0 rounded-0" id="fechaNacimiento" name="fecha_nacimiento" defaultValue="2023-07-22" min="1940-01-01" max="2023-12-31"/>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6'>
                                        <div className="mb-3">
                                            <input type="number" {...register("edad")}  className={`form-control border border-top-0 border-end-0 border-start-0 rounded-0 ${errors.edad ? 'is-invalid' : ''}`} id="edad" placeholder="Edad" name="edad"/>
                                        </div>
                                        {/* {errors.edad &&
                                            <div className="invalid-feedback d-block">{errors.edad?.message}</div>
                                        } */}
                                    </div>
                                    <div className='col-12 col-md-12'>
                                        <div className="mb-3">
                                            <input type="text" {...register("lugarDeNacimiento")} className={`form-control border border-top-0 border-end-0 border-start-0 rounded-0 ${errors.lugarDeNacimiento ? 'is-invalid' : ''}`} id="lugar_de_nacimiento" placeholder="Lugar de nacimiento" name="lugarDeNacimiento"/>
                                        </div>
                                        {/* {errors.lugarDeNacimiento &&
                                            <div className="invalid-feedback d-block">{errors.lugarDeNacimiento?.message}</div>
                                        } */}
                                    </div>
                                    <div className='col-12 col-md-12'>
                                        <div className="mb-3">
                                            <input type="text" {...register("nacionalidadOrigen")} className={`form-control border border-top-0 border-end-0 border-start-0 rounded-0 ${errors.nacionalidadOrigen ? 'is-invalid' : ''}`} id="nacionalidadOrigen" placeholder="Nacionalidad de origen" name="nacionalidadOrigen"/>
                                        </div>
                                        {/* {errors.nacionalidadOrigen &&
                                            <div className="invalid-feedback d-block">{errors.nacionalidadOrigen?.message}</div>
                                        } */}
                                    </div>
                                    <div className='col-12 col-md-12'>
                                        <div className="mb-3">
                                            <input type="text" {...register("nacionalidadAdquirida")} className={`form-control border border-top-0 border-end-0 border-start-0 rounded-0  ${errors.nacionalidadAdquirida ? 'is-invalid' : ''}`} id="nacionalidadAdquirida" placeholder="Nacionalidad adquirida" name="nacionalidadAdquirida"/>
                                        </div>
                                        {/* {errors.nacionalidadAdquirida &&
                                            <div className="invalid-feedback d-block">{errors.nacionalidadAdquirida?.message}</div>
                                        } */}
                                    </div>
                                    <div className='col-12 col-md-4'>
                                        <div className="mb-3">
                                            <select id="estadoCivil" {...register("estadoCivil")} className={`form-select border border-top-0 border-end-0 border-start-0 rounded-0 ${errors.estadoCivil ? 'is-invalid' : ''}`} aria-label="Casad(a)" name="estadoCivil">
                                                <option defaultValue=''>¿Casado?</option>
                                                <option value="Si">Si</option>
                                                <option value="No">No</option>
                                            </select>
                                        </div>
                                        {/* {errors.estadoCivil &&
                                            <div className="invalid-feedback d-block">{errors.estadoCivil?.message}</div>
                                        } */}
                                    </div>
                                    <div className='col-12 col-md-4'>
                                        <div className="mb-3">
                                            <select id="hijos" {...register("hijos")} className={`form-select border border-top-0 border-end-0 border-start-0 rounded-0 ${errors.hijos ? 'is-invalid' : ''}`} aria-label="Hijo(s)" name="hijos">
                                                <option defaultValue=''>¿Hijos?</option>
                                                <option defaultValue="Si">Si</option>
                                                <option defaultValue="No">No</option>
                                            </select>
                                        </div>
                                        {/* {errors.hijos &&
                                            <div className="invalid-feedback d-block">{errors.hijos?.message}</div>
                                        } */}
                                    </div>
                                    <div className='col-12 col-md-4'>
                                        <div className="mb-3">
                                            {/* <label className="form-check-label" htmlFor="hijosCantidad">Cantidad de hijos</label> */}
                                            <input type="number" {...register("hijosCantidad")} className={`form-control border border-top-0 border-end-0 border-start-0 rounded-0 ${errors.hijosCantidad ? 'is-invalid' : ''}`} id="hijosCantidad" placeholder="Cantidad de hijos" name="hijosCantidad"/>
                                        </div>
                                        {/* {errors.hijosCantidad &&
                                            <div className="invalid-feedback d-block">{errors.hijosCantidad?.message}</div>
                                        } */}
                                    </div>
                                    <div className='col-12 col-md-6'>
                                        <div className="mb-3">
                                            <select id="pep" {...register("pep")} className={`form-select border border-top-0 border-end-0 border-start-0 rounded-0 ${errors.pep ? 'is-invalid' : ''}`} aria-label="¿Es un PEPE's?" name="pep">
                                                <option defaultValue=''>¿Es un PEP'S?</option>
                                                <option defaultValue="Si">Si</option>
                                                <option defaultValue="No">No</option>
                                            </select>
                                        </div>
                                        {/* {errors.pep &&
                                            <div className="invalid-feedback d-block">{errors.pep?.message}</div>
                                        } */}
                                    </div>
                                    <div className='col-12 col-md-6'>
                                        <div className="mb-3">
                                            <input type="text" {...register("cargopep")} className={`form-control border border-top-0 border-end-0 border-start-0 rounded-0 ${errors.cargopep ? 'is-invalid' : ''}`} id="cargopep" placeholder="Cargo PEP" name="cargopep"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <div className='col-12 col-md-6'>
                            <div className={`${inner} p-4`}>
                                <h2 className='mb-4'>Medios de contacto</h2>
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className="mb-3">
                                            <input type="text" {...register("domicilioactual")} className={`form-control border border-top-0 border-end-0 border-start-0 rounded-0 ${errors.domicilioactual ? 'is-invalid' : ''}`} id="domicilioactual" placeholder="Domicilio actual" name="domicilioactual"/>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6'>
                                        <div className="mb-3">
                                            <input type="tel" {...register("celulares")} className={`form-control border border-top-0 border-end-0 border-start-0 rounded-0 ${errors.celulares ? 'is-invalid' : ''}`} id="celulares" placeholder="Celular(es)" name="celulares"/>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6'>
                                        <div className="mb-3">
                                            <input type="tel" {...register("telefonos")} className={`form-control border border-top-0 border-end-0 border-start-0 rounded-0 ${errors.telefonos ? 'is-invalid' : ''}`} id="telefonos" placeholder="Teléfono(s)" name="telefonos"/>
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className="mb-3">
                                            <input type="email" {...register("email")} className={`form-control border border-top-0 border-end-0 border-start-0 rounded-0 ${errors.email ? 'is-invalid' : ''}`} id="email" placeholder="Email" name="email"/>
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className="mb-3">
                                            <input type="text" {...register("actividadEconomica")} className={`form-control border border-top-0 border-end-0 border-start-0 rounded-0 ${errors.actividadEconomica ? 'is-invalid' : ''}`} id="actividadEconomica" placeholder="Actividad Económica" name="actividadEconomica"/>
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className="mb-3">
                                            <input type="text" {...register("ocupacion")} className={`form-control border border-top-0 border-end-0 border-start-0 rounded-0 ${errors.ocupacion ? 'is-invalid' : ''}`} id="ocupacion" placeholder="Ocupación" name="ocupacion"/>
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className="mb-3">
                                            <input type="text" {...register("lugardetrabajo")} className={`form-control border border-top-0 border-end-0 border-start-0 rounded-0 ${errors.ocupacion ? 'is-invalid' : ''}`} id="lugardetrabajo" placeholder="Lugar donde trabaja" name="lugardetrabajo"/>
                                        </div>
                                    </div>
                                    <div className='col-12'>
                                        <div className="mb-3">
                                            <input type="tel" {...register("telefonotrabajo")} className={`form-control border border-top-0 border-end-0 border-start-0 rounded-0 ${errors.telefonotrabajo ? 'is-invalid' : ''}`} id="telefonotrabajo" placeholder="Teléfono" name="telefonotrabajo"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
            </section>
            <section className='row justify-content-center align-items-center py-5' id={selectProductos}>
                <article className='col-12 col-md-8'>
                        <div className='row'>
                            <div className='col-12 text-center'>
                                <h2 className='mb-4'>Seleccione Productos y Servicios</h2>
                            </div>
                        </div>
                        <div className='row justify-content-center align-items-center'>
                            <div className='col-12'>
                                <ul id={productos_to_select}>
                                {
                                    productos.map(({ name, value }, index) => {
                                        return (
                                                <li key={index}>
                                                    <div className="form-check">
                                                        <input className={`form-check-input ${errors.productos ? 'is-invalid' : ''}`} type="checkbox" defaultValue={value} id={`custom-checkbox-${index}`} name='productos' checked={checkedState[index]} onClick={() => handleOnChange(index, value)} 
                                                        {...register("productos",{
                                                            required:{
                                                                value: true,
                                                                message: 'Productos es requerido'
                                                            }
                                                        })} aria-invalid={errors.productos ? "true" : "false"} />
                                                        <label className="form-check-label" htmlFor={`custom-checkbox-${index}`}>
                                                            {value}
                                                        </label>
                                                    </div>
                                                </li>
                                            );
                                    }
                                )}
                                </ul>
                                {errors.productos && <div className="invalid-feedback d-block  d-flex justify-content-start">{errors.productos?.message}</div>}
                            </div>
                        </div>
                </article>
            </section>
            <section className='row justify-content-center align-items-center pt-5' id={relacionComercial}>
                <article className='col-12 col-md-10'>
                    <div className={`${inner} p-4`}>
                        <div className="d-flex justify-content-start">
                            <h2 className='pb-4 self-align-left'>Relación comercial con coopinmobilia</h2>
                        </div>
                        <div className='row'>
                            <div className='col-12 d-flex flex-row align-items-center'>
                                <p className='mb-0 me-2'>¿Sera la única persona que utilizara su cuenta?</p>
                                <div className="form-check form-check-inline">
                                    <input {...register("utilizarcuenta")} className={`form-check-input ${errors.utilizarcuenta ? 'is-invalid' : ''}`} type="radio" name="utilizarcuenta" id="utilizar_cuenta" defaultValue="Si"/>
                                    <label className="form-check-label" htmlFor="utilizar_cuenta">Si</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input {...register("utilizarcuenta")} className={`form-check-input ${errors.utilizarcuenta ? 'is-invalid' : ''}`} type="radio" name="utilizarcuenta" id="utilizar_cuenta_no" defaultValue="No"/>
                                    <label className="form-check-label" htmlFor="utilizar_cuenta_no">No</label>
                                </div>
                            </div>
                            <div className='col-12 d-flex flex-row align-items-center'>
                                <p className='mb-0 me-2'>¿Desea una cuenta mancomunada?</p>
                                <div className="form-check form-check-inline">
                                    <input {...register("mancomunada")}  className={`form-check-input ${errors.mancomunada ? 'is-invalid' : ''}`} type="radio" name="mancomunada" id="cuenta_mancomunada" value="Si"/>
                                    <label className="form-check-label" htmlFor="cuenta_mancomunada">Si</label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input {...register("mancomunada")} className={`form-check-input ${errors.mancomunada ? 'is-invalid' : ''}`} type="radio" name="mancomunada" id="cuenta_mancomunada_no" defaultValue="No"/>
                                    <label className="form-check-label" htmlFor="cuenta_mancomunada_no">No</label>
                                </div>
                            </div>
                            <div className='col-12 pt-4'>
                                <div className="mb-3 d-flex justify-content-start flex-column align-items-start">
                                    <label htmlFor="proposito" className="form-label">¿Cuál es el propósito del servicio?</label>
                                    <textarea {...register("proposito")} className={`form-control border border-top-0 border-end-0 border-start-0 rounded-0  ${errors.proposito ? 'is-invalid' : ''}`} name="proposito" id="proposito" rows="3" col="10"></textarea>
                                </div>
                            </div>
                            <div className='col-12'>
                                <div className="mb-3 d-flex justify-content-start flex-column align-items-start">
                                    {/* <label htmlFor="ingresos" className="form-label">Ingresos Mensuales</label> */}
                                    <input {...register("ingresos")} type="number" className={`form-control w-100 w-md-50  border border-top-0 border-end-0 border-start-0 rounded-0  ${errors.ingresos ? 'is-invalid' : ''}`} name="ingresos" id="ingresos" placeholder="Ingresos Mensuales"/>
                                </div>
                            </div>
                            <div className='col-12'>
                                <div className="mb-3 d-flex justify-content-start flex-column align-items-start">
                                    {/* <label htmlFor="depositoInicial" className="form-label">Depósito Inicial de Ahorro</label> */}
                                    <input  {...register("depositoInicial")} type="number" className={`form-control w-100 w-md-50  border border-top-0 border-end-0 border-start-0 rounded-0  ${errors.depositoInicial ? 'is-invalid' : ''}`} name="depositoInicial" id="depositoInicial" placeholder="Depósito Inicial de Ahorro"/>
                                </div>
                            </div>
                            <div className='col-12 d-flex justify-content-start flex-column align-items-start'>
                                <p className='mb-0'>Formas de Depósitos</p>
                                {
                                    formas_depositos.map(({ name, value }, index) => {
                                        return(
                                            <div className="form-check" key={index}>
                                                <input onClick={()=> handleOnChangeForma(index, value)} className={`form-check-input ${errors.formadepositos && 'is-invalid' }`} name="formadepositos" type="checkbox" defaultValue={value} id={`forma_depositos_${index}`}   {...register("formadepositos",{
                                                    required:{
                                                        value: true,
                                                        message: 'Forma de depósito es requerido'
                                                    }
                                                })} aria-invalid={errors.formadepositos ? "true" : "false"} />
                                                <label className="form-check-label" htmlFor={`forma_depositos_${index}`}>
                                                    {value}
                                                </label>
                                            </div>
                                        )
                                    })
                                }
                                {errors.formadepositos && <div className="invalid-feedback d-block  d-flex justify-content-start">{errors.formadepositos?.message}</div>}
                            </div>
                            <div className='col-12 pt-4'>
                                <div className="mb-3 d-flex justify-content-start flex-column align-items-start">
                                    {/* <label htmlFor="depositoInicial" className="form-label">Promedio mensual de transacciones</label> */}
                                    <input  {...register("promedio")} type="number" className={`form-control w-100 w-md-50  border border-top-0 border-end-0 border-start-0 rounded-0 ${errors.promedio ? 'is-invalid' : ''}`} id="depositoInicial" placeholder="Promedio mensual de transacciones"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
            </section>
            <section className='row justify-content-center pt-3 pb-5' id={acuerdo}>
                <div className='col-12 col-md-10'>
                    <div className={`${inner} p-3 p-md-5`}>
                        <h2 className='mb-4'>Declaración voluntaria del origen de los fondos</h2>
                        <p><strong>Párrafo Primero.</strong> Origen de los fondos. Declaro que: Mis recursos tienen un origen lícito y provienen directamente del desarrollo de la actividad económica y ocupación señalada en el presente formulario, las cuales se desarrollan en conformidad al marco legal y normativo dominicano vigente. De igual forma autorizo recibir y suministrar mi información crediticia en los diferentes buros de crédito.</p>
                        <p><strong>Párrafo Segundo.</strong> Actualización de la Información. El Socio autoriza a COOPINMOBILIA a actualizar entregar a COOPINMOBILIA la totalidad de los soportes documentales exigidos y a actualizar la información suministrada con una periodicidad como mínimo anual y/o según se requiera, de conformidad con las normas legales y las circulares de los demás entes reguladores.</p>
                        <p><strong>Párrafo Tercero.</strong>  Autorizaciones de consulta, veriﬁcaciones y suministro de informaciones. Asimismo autorizamos de forma libre, consciente y expresa a COOPINMOBILIA a recibir y suministrar nuestra información crediticia en los diferentes buros de créditos nacionales e internacionales, así como también, veriﬁcar la información depositada en este formulario y consulte de datos internas y/o externas disponibles o centros de información nacionales e internacionales que COOPINMOBILIA considere para realizar la debida diligencia para la vinculación y permanencia como socios.</p>
                        <p><strong>Párrafo cuarto.</strong> Autorización de administración y remisión de información. Autorizamos de forma expresa e irrevocable a COOPINMOBILIA para que, en caso de ser requerido por cualquier autoridad judicial, administrativa, cambiaria, tributaria o de otra índole, tanto en República Dominicana como en cualquier otro país, entregue la información solicitada, actuando de conformidad con las normas legales que resulten aplicables, manteniendo libre e indemne a COOPINMOBILIA de toda reclamación o acusación por este concepto.</p>
                        <p>Con carácter de Declaración Jurada y bajo pena de perjurio, certiﬁcamos haber examinado toda la información que aparece en este formulario y, según nuestro leal saber y entender, aﬁrmamos que es verdadera, correcta y completa y en caso de que algunas de las declaraciones o certiﬁcaciones aquí descritas resulten incompletas o incorrectas, suministraremos un nuevo formulario y los soportes que justiﬁquen el error en un plazo no mayor de 3 días calendarios.</p>
                    </div>
                </div>
                <div className="col-12 col-md-10 mt-5">
                    <button type="submit" className="btn btn-dark" > Aceptar términos y Enviar solicitud </button>
                </div>
            </section>
        </form>
    )
}

export default memo(FormComponent);