import React, {memo, useEffect, useRef, useState} from 'react';
import styles from './styles.module.css';
import Logo from '../../assets/images/footer_logo.png';
import Certificado from '../../assets/images/certificado.png';

let Footer = () => {
    let {footer, servicios_menu, contact_emails} = styles;
    let [servicios, setServicios] = useState([]);
    let [loading, setLoading] = useState(true);
    let query = useRef(true);

    useEffect(() => {

        if(query.current){
            query.current = false;
            fetch('https://coopinmobilia.com/dashboard/wp-json/wp/v2/productos-servicios/?_embed&status=publish&per_page=40')
            .then((response) => response.json())
            .then(async(data)  => {
                setServicios(data);
                setLoading(false);
            })
            .catch((err) => {
                //console.log(err.message);
                setLoading(false);
            });
        }

    },[]);

    return(
        <footer className='row pt-5 justify-content-center align-items-center' id={footer}>
            <div className='col-12 col-md-10 py-3'>
                <div className='row'>
                    <div className='col-12 col-md-12 col-lg-2 d-flex align-items-start flex-column mb-4 mb-md-0'>
                        <img src={Logo} alt ='Logo' className='img-fluid mb-4'/>
                        <img src={Certificado} alt='Certificado' className='img-fluid'/>
                    </div>
                    <div className='col-12 col-sm-12 col-md-12 col-lg-3  mb-4 mb-md-0'>
                        <ul>
                            <li><a href='/'>inicio</a></li>
                            <li><a href='/nosotros'>nosotros</a></li>
                            <li><a href='/productos-y-servicios'>productos y servicios</a></li>
                            <li><a href='/contactos'>contactenos</a></li>
                            <li><a href='/asociarse'>asociarse</a></li>
                        </ul>
                    </div>
                    <div className='col-12 col-sm-12 col-md-12 col-lg-4  mb-4 mb-md-0'>
                        
                        <div className="row">
                            <div className="col-12 col-lg-6 mb-4 mb-md-0">
                                <h6>CR&Eacute;DITOS</h6>
                                <ul className={servicios_menu}>
                                    {
                                        servicios.map(servicio => {
                                            return(
                                                servicio.acf.tipo_de_producto  === 'credito' ? <li key={servicio.id}><a href={`/producto/${servicio.id}/${servicio.slug}`}>{servicio.acf.nombre_del_producto}</a></li> : null
                                            )
                                        })
                                    }
                                    </ul>
                            </div>
                            <div className="col-12 col-lg-6">
                                <h6>AHORRO</h6>
                                <ul className={servicios_menu}>
                                {
                                    servicios.map(servicio => {
                                        return(
                                            servicio.acf.tipo_de_producto  === 'ahorro' ? <li key={servicio.id}><a href={`/producto/${servicio.id}/${servicio.slug}`}>{servicio.acf.nombre_del_producto}</a></li> : null
                                        )
                                    })
                                }
                            
                                </ul>
                            </div>
                        </div>    
                    </div>
                    <div className='col col-sm-12 col-md-12 col-lg-3'>
                        <div className='mb-3 py-1 d-flex flex-row justify-content-start'>
                            <div className='d-flex'>
                                <a href='https://www.instagram.com/coopinmobilia/' target="_blank" rel="noreferrer" alt="@coopinmobilia">
                                    <span style={{color: '#80BFB3'}}>
                                        <i className="fa-brands fa-instagram fa-2xl"></i>
                                    </span>
                                </a>
                            </div>
                        </div>
                        <ul className={contact_emails}>
                            <li><a href='mailto:info@coopinmobilia.com'>info@coopinmobilia.com</a></li>
                        </ul>
                        <hr/>
                        <div className="">
                            <span>809.475.7171</span>	<span>|</span>	<span>849.206.7572</span>
                        </div>
                        <hr/>
                        <address>
                            Calle Eugenio Deschamps No. 59,
                            Los Prados, Santo Domingo, D.N.
                        </address>
                    </div>
                </div>
                <div className='row'>
                    <div className='col col-12 py-3 text-center'>
                        <hr/>
                        <p className='m-0 pt-3'>© {new Date().getFullYear()} Coopinmobilia. Todos los derechos reservados.</p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default memo(Footer);