import React, { useRef, memo } from 'react';
import emailjs from '@emailjs/browser';
import Styles from './styles.module.css';
import Buttons from '../../commons/home/styles.module.css';

let Form = () =>{
    let {form, inner} = Styles;
    let {button, greenButton} = Buttons;
    let contact_form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_zz3uzar', 'template_jmjr5yq', contact_form.current, 'RNnfTJLhURFgPkZk3')
          .then((result) => {
              console.log(result.text);
              alert('Mensaje enviado con exito!');
          }, (error) => {
              console.log(error.text);
          });
    };

    return (
        <section className='row justify-content-center pb-5' id={form}>
            <div className='col-12 col-md-10'>
                <div className='row'>
                    <article className='col-12 col-md-6'>
                        <div className={`${inner} p-3 p-md-5 mb-4`}>
                            <form ref={contact_form} onSubmit={sendEmail}>
                                <div className="mb-3">
                                    <input type="text" required placeholder='Nombre' name="name" className="form-control rounded-0 border border-top-0 border border-end-0 border-start-0" id="name"/>
                                </div>
                                <div className="mb-3">
                                    <input type="email" required name="email" placeholder='Correo electrónico' className="form-control rounded-0 border border-top-0 border border-end-0 border-start-0" id="email"/>
                                </div>
                                <div className="mb-3">
                                    <input type="text" required name="subject" placeholder='Asunto' className="form-control rounded-0 border border-top-0 border border-end-0 border-start-0" id="asunto"/>
                                </div>
                                <div className="mb-3">
                                    <textarea required placeholder='Mensaje' name="message" className="form-control border rounded-0 border-top-0 border border-end-0 border-start-0" id="mensaje" rows="3"></textarea>
                                </div>
                                <button type="submit" className={`${[button, greenButton].join(' ')} btn btn-primary`}>Enviar</button>
                            </form>
                        </div>
                    </article>
                    <article className='col-12 col-md-6 d-flex align-items-center'>
                        <div className='row'>
                            <div className='col-12'>
                                <h6>Dirección:</h6>
                                <p>Calle Eugenio Deschamps No. 59, Los prados, Santo Domingo, D.N.</p>
                            </div>
                            <div className='col-12'>
                                <h6>Teléfono:</h6>
                                <p>809.475.7171</p>
                                <p>849.206.7572</p>
                            </div>
                            <div className='col-12'>
                                <h6>Email:</h6>
                                <ul>
                                    <li>info@coopinmobilia.com</li>
                                    {/* <li>cmedina@coopinmobilia.com.do</li>
                                    <li>contabilidad@coopinmobilia.com.do</li>
                                    <li>gestionhumana@coopinmobilia.com</li> */}
                                </ul>
                            </div>
                        </div>
                    </article>
                </div>
            </div>
        </section>
    )

}

export default memo(Form);