import React from 'react';
import Style from './styles.module.css';

let SolicitarButton = () => {

    let {solicitar, greenButton, button} = Style;
    return(
        <section className="row justify-content-center align-items-center pb-4" id={solicitar}>
            <article className="col-12 col-md-10 py-4 ">
                <a href='/asociarse' className={[button, greenButton].join(' ')}>Solicitar</a>
            </article>
        </section>
    )
}

export default SolicitarButton