import React,{memo, useRef} from 'react';
import emailjs from '@emailjs/browser';
import Background from '../../../assets/images/contactos/buzon_bg.jpg';
import Styles from './styles.module.css';
import Buttons from '../home/styles.module.css';

let Sugerencias = () => {
    let {sugerencias} = Styles;
    let {button, greenButton} = Buttons;
    let contact_form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_zz3uzar', 'template_0v4eqlr', contact_form.current, 'RNnfTJLhURFgPkZk3')
          .then((result) => {
              console.log(result.text);
              alert('Mensaje enviado con exito!');
          }, (error) => {
              console.log(error.text);
          });
    };

    return(
        <section className='row py-5' id={sugerencias} style={{backgroundImage:`url(${Background})`}}>
            <article className='col-12 col-md-12'>
                <div className='row justify-content-center'>
                    <div className='col-12 text-center mb-5 pt-2'>
                        <h2>Buzón de Sugerencias</h2>
                    </div>
                </div>
                <div className='row justify-content-center'>
                    <form className='col-12 col-md-6' ref={contact_form} onSubmit={sendEmail}>
                        <div className="mb-5">
                            <select required name="subject" className="form-select rounded-0 border border-0" aria-label="Seleccionar tema">
                                <option value=''>Seleccionar tema</option>
                                <option value="Propuesta de otros productos o servicios">Propuesta de otros productos o servicios</option>
                                <option value="Quejas y reclamaciones">Quejas y reclamaciones</option>
                                <option value="Comentarios y/o recomendaciones">Comentarios y/o recomendaciones</option>
                            </select>
                            </div>
                        <div className="mb-5">
                            <textarea name="comentario" required className="form-control rounded-0 border border-0" id="comentario" rows="5" defaultValue='' placeholder='Comentario o sugerencia'></textarea>
                        </div>
                        <div className='mb-5 text-center'>
                            {/* <a href="/" className={[button, greenButton].join(' ')}>Enviar</a> */}
                            <button type='submit' className={`btn ${[button, greenButton].join(' ')}`}>Enviar</button>
                        </div>
                    </form>
                </div>
            </article>
        </section>
    )
};

export default memo(Sugerencias);