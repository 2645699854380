export const productos = [
    {
        "name": "ahorro_a_la_vista",
        "value": "Ahorro a la vista",
    },
    {
        "name": "coopsan",
        "value": "Coop San"
    },
    {
        "name": "CoopNav",
        "value": "Coop Nav"
    },
    {
        "name": "CoopRetiro",
        "value": "Coop Retiro"
    },
    {
        "name": "AhorroInfantil",
        "value": "Ahorro Infantil"
    },
    {
        "name": "certificadoInversion",
        "value": "Certificado de Inversión"
    },
    {
        "name": "PlazoFijo",
        "value": "Plazo Fijo"
    },
    {
        "name": "Aportaciones",
        "value": "Aportaciones"
    },
    {
        "name": "bolsa_de_inversion",
        "value": "Bolsa de inversión"
    },
    {
        "name": "Creditos",
        "value": "Créditos"
    },
    {
        "name": "UniCoop",
        "value": "UniCoop"
    },
    {
        "name": "Coop Vacaciones",
        "value": "CoopVacaciones"
    },
    {
        "name": "Coop Car",
        "value": "CoopCar"
    },
    {
        "name": "Coop Salud",
        "value": "CoopSalud"
    },
    {
        "name": "Coop Techo",
        "value": "CoopTecho"
    },
    
]

