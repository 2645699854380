import React,{memo} from 'react';
import GMap from './commons/contactos/GMap';
import Title from './commons/contactos/Title';
import Form from './commons/contactos/Form';
import Futuro from './commons/nosotros/Futuro';
import Sugerencias from './commons/contactos/Sugerencias';

let Contactos = () =>{
    return(
        <>
            <GMap/>
            <Title/>
            <Form/>
            <Sugerencias/>
            <Futuro/>
        </>
        
    )
}

export default memo(Contactos);