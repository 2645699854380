import React,{memo, useEffect, useState, useRef} from  'react';
import HomeAbout from '../../../assets/images/home/about_home.jpg';
import Logo from '../../../assets/images/home/logo.png';
import styles from './styles.module.css';

let AboutHome = () =>{
    let {about_section, about_logo,about_sectionInner, button, greenButton} = styles;

    return(
        <section className='row'id={about_section}  style={{backgroundImage:`url(${HomeAbout})`}}>
            <article className='col-12'>
                <div className='row d-flex h-100'>
                    <div className='col-12'>
                        <div className={`${about_sectionInner} d-flex justify-content-center align-items-center flex-column h-100`}>
                            <div className='row'>
                                <div className='col-12 d-flex justify-content-center  mb-4 mb-lg-5'>
                                    <img src={Logo} alt="logo" className={about_logo}/>
                                </div>
                            </div>
                            <div className='row justify-content-center align-items-center '>
                                <div className='col-12 col-lg-8 d-flex flex-column justify-content-center align-items-center'>
                                    <h5 className='mb-lg-5'>Cooperativa de Ahorro, Crédito y Servicios Múltiples del Sector Inmobiliario</h5>
                                    <p  className='mb-lg-5 mx-lg-5 px-lg-5'>Somos una cooperativa de servicios financieros, comprometidos con el desarrollo económico sostenible de nuestros socios y la comunidad.</p>
                                    <a href='/nosotros' className={[button,greenButton].join(' ')}>Saber Más</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </article>
        </section>
    );
};

export default memo(AboutHome);