import React, {memo} from 'react';
import Styles from '../nosotros/styles.module.css';
import styles from '../home/styles.module.css';
import Shape from '../../../assets/images/shape.png';

let Futuro = () =>{
    let {futuro} = Styles;
    let {button, grayButton} = styles;
    return(
        <>
            <section className='row py-5 justify-content-center text-center' id={futuro}>
                <div className='col-12 col-md-8 py-5'>
                    <h5 className='mb-5'>Juntos por el desarrollo de un futuro económico sostenible.</h5>
                    <a href='/asociarse' className={[button, grayButton].join(' ')}>Asociarse</a>
                </div>
            </section>
        </>
    )
}

export default memo(Futuro);

