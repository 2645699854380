import React from 'react';
import { Outlet } from "react-router-dom";
import MainNavigation from '../pages/commons/MainNavigation';
import Footer from '../pages/commons/Footer';

let Layout = () => {
    return(
        <>
            <MainNavigation/>
            <main className="container-fluid">
                <Outlet/>
                <Footer/>
            </main> 
        </>
    )
}

export default Layout;