import React from 'react';
import Style from "./styles.module.css";
import { Parser } from "html-to-react";

let ProductoCaracteristicas = ({content}) => {
    const htmlParser = new Parser();
    let{caracteristicas} = Style;

    return(
        <section className="row justify-content-center align-items-center pt-5 " id={caracteristicas}>
            <article className="col-12 col-md-10">
                <h3 className='d-flex align-self-start'>Características</h3>
                {htmlParser.parse(content)}
            </article>
        </section>
    )
}
export default ProductoCaracteristicas;