import React,{memo, useEffect, useState, useRef} from 'react';
import Title from './commons/producto/Title';
import ImageBanner from './commons/producto/ImageBanner';
import ProductoDescription from './commons/producto/ProductoDescription';
import ProductoRequisitos from './commons/producto/ProductoRequisitos';
import ProductoBeneficios from './commons/producto/ProductoBeneficios';
import ProductoCaracteristicas from './commons/producto/ProductoCaracteristicas';
import SolicitarButton from './commons/producto/SolicitarButton';
// import Creditos from './commons/productosyservicios/Creditos';
// import Ahorro from './commons/productosyservicios/Ahorro';
import { useParams } from 'react-router-dom';

let ProductoPage = () => {
    let { id, slug } = useParams();
    let query = useRef(true);
    // let [allProducts, setAllProducts] = useState([]);
    let [productName, setProductName] = useState();
    let [description, setDescription] = useState()
    let [requisitos, setRequisitos] = useState();
    let [caracteristicas, setCaracteristicas] = useState();
    let [beneficios, setBeneficios] = useState();
    let [image, setImage] = useState();
    // let [creditos, setCreditos] = useState([]);
    // let [reditos, setReditos] = useState([]);

    useEffect(()=>{
        console.log("noticia ID: ", id, "slug id:", slug);
        if(query.current){
            query.current = false;
            fetch(`https://coopinmobilia.com/dashboard/wp-json/wp/v2/productos-servicios/${id}/?_embed&status=publish`)
            .then((response) => response.json())
            .then(async(data)  => {
                console.log('Producto data:', data);
                console.log("image", data._embedded['wp:featuredmedia'][0].source_url);
                setProductName(data.acf.nombre_del_producto);
                setDescription(data.acf.descripcion_completa_del_producto);
                setRequisitos(data.acf.prerequisitos);
                setBeneficios(data.acf.beneficios);
                setCaracteristicas(data.acf.caracteristicas);
                setImage(data._embedded['wp:featuredmedia'][0].source_url);
                // data.map(noticia => {
                //     fetch(`https://coopinmobilia.com/dashboard/wp-json/wp/v2/media/${noticia.featured_media}`)
                //     .then(response => response.json())
                //     .then( data => {
                //         noticia.portada =  data.media_details.sizes.full.source_url;
                //         //console.log('portada', noticia.portada);
                //     })
                //     .catch((err) => {
                //         console.log(err.message);
                //     });
                //     newArray.push(noticia);
                // });
                
                //setServicios(data);
                //console.log(data);
                //setLoading(false);
            })
            .catch((err) => {
                //console.log(err.message);
                //setLoading(false);
            });
        }
    },[id, slug]);


    return(
        <>
            <Title />
            <ImageBanner title={productName} content={description} imagen={image}/>
            {/* {description ? <ProductoDescription title={productName} description={description} /> : null} */}
            {caracteristicas ? <ProductoCaracteristicas content={caracteristicas}/> : null }
            {beneficios ? <ProductoBeneficios content={beneficios}/> : null }
            {requisitos ? <ProductoRequisitos prerequisitos={requisitos}/> : null } 
            <SolicitarButton/>
        </>
    )
};

export default memo(ProductoPage);
