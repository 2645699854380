import React,{useState, useEffect, useRef} from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Styles from './styles.module.css';
import Futuro from '../nosotros/Futuro';
import Shape from '../../../assets/images/shape.png';

let Ahorro = () => {
    let {ahorro, inner, subtitle} = Styles;
    let [servicios, setServicios] = useState([]);
    let [loading, setLoading] = useState(false);
    let query = useRef(true);

    useEffect(()=>{
        const productos = JSON.parse(localStorage.getItem('productos'));
        const url = "https://coopinmobilia.com/dashboard/wp-json/wp/v2/productos-servicios/?per_page=100&status=publish";
        if(query.current && productos === null ){
            query.current = false;
            setLoading(true);
            fetch(url)
            .then((response) => response.json())
            .then(async(data)  => {
                localStorage.setItem('productos', JSON.stringify(data));
                setServicios(data);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err.message);
                setLoading(false);
            });
        }else{
            setServicios(productos);
            setLoading(false);
            
            const interval = setInterval(() => {
                fetch(url)
                .then((response) => response.json())
                .then(async(data)  => {
                    localStorage.removeItem('productos');
                    localStorage.setItem('productos', JSON.stringify(data));
                    setServicios(data);   
                })
                .catch((err) => {
                    console.log(err.message);
                
                });
            }, 30000);
            return () => clearInterval(interval);
        }
    },[]);
    return(
        <>
        <section className="row justify-content-center align-items-center py-5" id={ahorro}>
            <div className="col-12 col-md-10">
                <article className="row justify-content-center align-items-center">
                    <div className="col-12 col-md-8 text-center mb-4">
                        <h2>Ahorro</h2>
                        <p className={subtitle}>Te ofrecemos los planes ideales para asesorarte a invertir en un objetivo a futuro y una buena salud ﬁnanciera.</p>
                    </div>
                </article>
                <article className="row justify-content-center align-items-center">
                {loading ? (
                            <div className='col-12 col-md-5 text-center pb-5 mb-5'>
                                <Spinner animation="border" role="status" variant="success">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </div>
                        ):
                
                    servicios.map(servicio => {
                        return(
                            servicio.acf.tipo_de_producto  === 'ahorro' ?
                                <div className="col-12 col-md-4 mb-4" key={servicio.id}> 
                                    <div className={`${inner} py-5 px-4 h-100 d-flex flex-column justify-content-center align-items-center`}>
                                        <a href={`/producto/${servicio.id}/${servicio.slug}`} style={{textDecoration:'none'}}>
                                            <img src={servicio.yoast_head_json.og_image[0].url} alt='Ahorro a la vista' className="img-fluid mb-2" />
                                            <h3>{servicio.acf.nombre_del_producto}</h3>
                                            <p>{servicio.acf.descripcion_corta_del_producto}</p>
                                        </a>
                                    </div>
                                </div>
                            : 
                            null
                           )
                    })
                }
                </article>
            </div>
            <Futuro/>
        </section>
        <section className='row justify-content-center' style={{backgroundColor:'#F2F3F4'}}>
            <div className='col-12 px-0 pt-5 pb-4'>
                <img src={Shape} alt='shape' className='img-fluid'/>
            </div>
        </section>
        </>
    )
};

export default Ahorro;