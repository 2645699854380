import React,{memo} from 'react';
import Title from './commons/productosyservicios/Title';
import Creditos from './commons/productosyservicios/Creditos';
import Ahorro from './commons/productosyservicios/Ahorro';
import shape from '../assets/images/shape.png';

let ProductosServiciosPage = () => {
    return(
        <>
            <Title />
            <Creditos/>
            <Ahorro/>
        </>
    )
};

export default memo(ProductosServiciosPage);