import React,{memo} from 'react';
import Title from './commons/asociarse/Title';
import FormComponent from './commons/asociarse/Form';

let AsociarsePage = () => {
    return(
        <>
            <Title/>
            <FormComponent/>
        </>
    )
}

export default memo(AsociarsePage);