import React from 'react';
import NoticiasList from './commons/noticias/NoticiasList';
import Title from './commons/noticias/Title';


let NoticiasPage = () => {
    return(
        <>
            <Title/>
            <NoticiasList/>
            {/* <Futuro/> */}
        </>
    )
};

export default NoticiasPage;