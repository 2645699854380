import React,{useEffect, useRef, useState} from 'react';
import parse from "html-react-parser";
import {decodeEntities} from "@wordpress/html-entities";
import Spinner from 'react-bootstrap/Spinner';
import {detailsNews} from '../app/redux/noticiasDucks';
import {connect} from 'react-redux';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

let NoticiaPage = () => {
    let { id } = useParams();
    let query = useRef(true);
    let [noticia, setNoticia] = useState({});
    let [loading, setLoading] = useState(true);

    useEffect(()=>{
      let url = `https://coopinmobilia.com/dashboard/wp-json/wp/v2/noticias/${id}?status=publish`;
      fetch(url)
          .then((response) => response.json())
          .then(async(data)  => {

              setNoticia(data);
              setLoading(false);
              console.log('news details:',data);
          })
          .catch((err) => {
              console.log(err.message);
              setLoading(false)
          });
        
    },[id]);


    let renderNews = (n) => {
      return(
        n ?
          <div className='row justify-content-center align-items-center'>
            <div className='col-12 col-md-12' key={n.id}>
              <h1>{n.title.rendered}</h1>
                <p><small className="text-muted">{parse(decodeEntities(new Date(n.date).toLocaleDateString('es-us', {weekday:"long", year:"numeric", month:"long", day:"numeric"})))}</small></p>
                {n.yoast_head_json.og_image[0].url ? <img className='img-fluid w-100' src={n.yoast_head_json.og_image[0].url} alt={n.title.rendered} /> : null}
                <div className="py-5">{parse(n.content.rendered)}</div>
            </div>
          </div>
         :
         <div className="row justify-content-center">
            <div className='col-12 text-center pb-5 mb-5'>
                <h3>Lo sentimos, esta noticia no existe</h3>
            </div>
          </div>
      );
    }

    return(
          <section className='row justify-content-center align-items-center py-5' style={{marginTop:'120px', backgroundColor:'#F2F3F4'}}>
              <article className='col col-md-10 col-lg-8 py-5'>
              {loading ? (
                        <div className="row justify-content-center">
                          <div className='col-12 text-center pb-5 mb-5'>
                              <Spinner animation="border" role="status" variant="success">
                                  <span className="visually-hidden">Loading...</span>
                              </Spinner>
                          </div>
                          </div>
                      ) : (
                      renderNews(noticia)
                    )}
              </article>
          </section>
            
    )
};

const mapStateToProps = state => {
    return {
      details: state.newsReducer.newsDetails,
      loading: state.newsReducer.loadingNewsDetails,
    };
  };
  const mapDispatchToProps = dispatch => {
    return {
      loadNews: id => dispatch(detailsNews(id)),    
    };
  };

export default connect(mapStateToProps,mapDispatchToProps)(NoticiaPage);