import React,{useEffect, useRef, useState} from 'react';
import {decodeEntities} from "@wordpress/html-entities";
import parse from "html-react-parser";
import Spinner from 'react-bootstrap/Spinner';
import Shape from '../../../assets/images/shape.png';
import styles from './styles.module.css';

let NoticiasHome = ({loadNews, loading, news}) => {
    let {noticiasHome, backgroundCover, card_noticia, button,grayButton} = styles;
    let [noticiasAll, setNoticiasAll] = useState([]);
    let queryNoticias = useRef(true);

    useEffect(()=>{
        const noticias = JSON.parse(localStorage.getItem('home_news'));
        let url ='https://coopinmobilia.com/dashboard/wp-json/wp/v2/noticias?status=publish&per_page=4';
        if(queryNoticias.current && noticias === null){

            fetch(url)
            .then((response) => response.json())
            .then(async(data)  => {

                localStorage.setItem('home_news', JSON.stringify(data));
                setNoticiasAll(data);
            })
            .catch((err) => {
                console.log(err.message);
            
            });
            
        }else{

            setNoticiasAll(noticias);
            const interval = setInterval(() => {
                fetch(url)
                .then((response) => response.json())
                .then(async(data)  => {
                    localStorage.removeItem('home_news');
                    localStorage.setItem('home_news', JSON.stringify(data));
                    setNoticiasAll(data);     
                })
                .catch((err) => {
                    console.log(err.message);
                
                });
            }, 900000);
            return () => clearInterval(interval);

        }

    },[]);
    return(
       <>
        <section className='row  justify-content-center align-items-center py-5' id={noticiasHome}>
            <div className='col col-10'>
                <div className='row '>
                    <div className='col col-12 text-center pb-3'><h2>Noticias</h2></div>
                </div>
                <div className='row justify-content-center align-items-center'>
                        {loading? (
                            <div className='col-12 col-md-5 text-center pb-5 mb-5'>
                                <Spinner animation="border" role="status" variant="success">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </div>
                        ):

                        noticiasAll.length === 0 ? ( 
                            <div className='col-12 col-md-5 text-center pb-5 mb-5'>
                                <h3>No hay noticias publicadas en este momento</h3>
                            </div>
                        ) :
                        
                        noticiasAll.map(noticia => {
                            
                            return (
                                <div className='col-12 col-md-6' key={noticia.id}>
                                    <article className={`${card_noticia} card shadow-sm bg-body-tertiary mb-3 rounded-0 border border-0`}>
                                    <a href={`/noticias/${noticia.id}/${noticia.slug}`}>
                                        <div className="row g-0">
                                            <div className="col-12 col-md-12 col-xl-4 p-0">
                                                <div className={`${backgroundCover} d-flex p-5 cover cover_${noticia.id} `} style={{backgroundImage:`url(${noticia.yoast_head_json.og_image[0] ? noticia.yoast_head_json.og_image[0].url: null})`}}></div>
                                            </div>
                                            <div className="col-12 col-md-12 col-xl-8 ">
                                                <div className="card-body d-flex flex-column h-100 justify-content-center">
                                                    <h5 className="card-title">{noticia.title.rendered.slice(0, 70)}</h5>
                                                    <p className="card-text"><small className="text-muted">{parse(decodeEntities(new Date(noticia.date).toLocaleDateString('es-us', {weekday:"long", year:"numeric", month:"long", day:"numeric"})))}</small></p>
                                                </div>
                                            </div>
                                        </div>
                                        </a>
                                    </article>
                                </div>
                            )
                        })
                    }
                </div>
                <div className='row justify-content-center align-items-center'>
                    <div className='col col-10 text-center my-5'>
                        <a href='/noticias' className={[button,grayButton].join(' ')}>Más noticias</a>
                    </div>
                </div>
            </div>
        </section>
        <section className='row justify-content-center align-items-center mt-0' style={{backgroundColor:'#F2F3F4'}}>
            <div className='col-12  px-0 pt-5 pb-4'>
                <img src={Shape} className='img-fluid' alt='Shape' />
            </div>
        </section>
        </>
    );
};

export default NoticiasHome;