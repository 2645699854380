import React from 'react';
import NosotrosIntro from './commons/nosotros/NosotrosIntro';
import EstructuraOrganizacional from './commons/nosotros/EstructuraOrganizacional';
import Futuro from './commons/nosotros/Futuro';
import Shape from '../assets/images/shape.png';

let NosotrosPage = () => {
    return(
        <>
            <NosotrosIntro/>
            <EstructuraOrganizacional/>
            <Futuro/>
            <section className='row justify-content-center align-items-center mt-0'>
                <div className='col-12  px-0 pt-5 pb-4'>
                    <img src={Shape} className='img-fluid' alt='Shape' />
                </div>
            </section>
        </>
        
    )
}

export default NosotrosPage;