import React from 'react';
import styles from './styles.module.css';
import credito from '../../../assets/images/home/credito.png';
import ahorro from '../../../assets/images/home/ahorro.png';

let ProductosServicios = () => {
    let {productos_servicios, button, inner_servicio, inner_servicio_p, inner_servicio_h3,inner_servicio_pGreen, greenButton} =  styles
    return(
        <section className='row pb-2 py-md-5' id={productos_servicios}>
            <article className='col-12'>
                <div className='row justify-content-center align-items-center py-5'>
                    <div className='col-12 col-md-10'>
                        <div className='row'>
                            <div className='col-12 col-md-12 col-lg-4 mb-5 mb-md-0'>
                                <div className='row h-100 align-items-center'>
                                    <div className='col-12'>
                                        <h3>Encuentra con nosotros el plan indicado para ti.</h3>
                                        <p className={inner_servicio_pGreen}>¿Necesitas un vehículo?<br/> ¿Necesitas un inmueble?<br/> ¿Quieres ir de viajes?</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-md-12 col-lg-8'>
                                <div className='row'>
                                    <div className='col-12 d-flex justify-content-center pb-4'>
                                        <h2>Productos y Servicios</h2>
                                    </div>
                                </div>
                                <div className='row gx-2'>
                                    <div className='col-12 col-md-6 mb-3 mb-md-0'>
                                        <div className={`${inner_servicio} p-3 d-flex flex-column justify-content-between align-items-center  py-5`}>
                                            <img src={ahorro} alt="Ahorro"/>
                                            <h3 className={inner_servicio_h3}>Ahorro</h3>
                                            <p className={inner_servicio_p}>Te ofrecemos los planes ideales para asesorarte a invertir en un objetivo a futuro y una buena salud ﬁnanciera.</p>
                                            <a href="/productos-y-servicios" className={[button,greenButton].join(' ')}>Saber Más</a>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-6'>
                                        <div className={`${inner_servicio} p-3 d-flex flex-column justify-content-between align-items-center  py-5`}>
                                            <img src={credito} alt="Crédito"/>
                                            <h3 className={inner_servicio_h3}>Crédito</h3>
                                            <p className={inner_servicio_p}>Te apoyamos con esa casa, ese negocio o eso que tanto deseas ahora, para ti y tu familia.</p>
                                            <a href="/productos-y-servicios" className={[button, greenButton].join(' ')}>Saber Más</a>
                                        </div>
                                    </div>
                                </div>
                            </div>  
                        </div>
                    </div>
                </div>
            </article>
        </section>
    )
};

export default ProductosServicios;