import React from  'react';
import PrimarySlider from './commons/home/PrimarySlider';
import AboutHome from './commons/home/AboutHome';
import ProductosServicios from './commons/home/ProductosServicios';
import ProductosServiciosSlider from './commons/home/ProductosServiciosSlider';
import AfiliarseHome from './commons/home/AfiliarseHome';
import NoticiasHome from './commons/home/NoticiasHome';
//import { AppContext } from '../context/AppState';

let HomePage = () => {
    return(
        <>
            <PrimarySlider/>
            <AboutHome/>
            <ProductosServicios/>
            <ProductosServiciosSlider/>
            <AfiliarseHome/>
            <NoticiasHome/>
        </>
    )
};

export default HomePage;