import React,{useEffect, useRef, useState} from 'react';
import parse from "html-react-parser";
import {decodeEntities} from "@wordpress/html-entities";
import Spinner from 'react-bootstrap/Spinner';
import styles from '../home/styles.module.css';

let NoticiasList = () => {
    let {noticiasHome, backgroundCover, card_noticia} = styles;
    // let [page , setPage] = useState(1);
    // let [offset, setOffSet] = useState(13);
    let query = useRef(true);
    let [noticias, setNoticias] = useState([]);
    let [loading, setLoading] = useState(false);
    
    useEffect(()=>{
        const noticias = JSON.parse(localStorage.getItem('all_news'));
        let url = `https://coopinmobilia.com/dashboard/wp-json/wp/v2/noticias/?per_page=18&status=publish`;
        if(query.current && noticias === null){
            setLoading(true);
            
            fetch(url)
            .then((response) => response.json())
            .then(async(data)  => {
                localStorage.setItem('all_news', JSON.stringify(data));
                setNoticias(data);
                setLoading(false);
            })
            .catch((err) => {
                console.log('error request: ',err.message);
                setLoading(false);
            });
        }else{
            setNoticias(noticias);
            setLoading(false);
            const interval = setInterval(() => {
                fetch(url)
                .then((response) => response.json())
                .then(async(data)  => {
                    localStorage.removeItem('all_news');
                    localStorage.setItem('all_news', JSON.stringify(data));
                    setNoticias(data);   
                })
                .catch((err) => {
                    console.log(err.message);
                
                });
            }, 30000);
            return () => clearInterval(interval);
        }
        
    },[]);

    let renderNews = (n) => {
        return(
            n.length > 0 ?
                <div className='col-12' id={noticiasHome}>
                    <div className='row justify-content-center'>
                    {n.map(noticia => {
                                    
                        return (
                            <div className='col-12 col-md-6' key={noticia.id}>
                                <article className={`${card_noticia} card shadow-sm bg-body-tertiary mb-3 rounded-0 border border-0`}>
                                    <a href={`/noticias/${noticia.id}/${noticia.slug}`}>
                                    <div className="row g-0">
                                        <div className="col-12 col-md-12 col-xl-4 p-0">
                                            <div className={`${backgroundCover} d-flex p-5 cover cover_${noticia.id} `} style={{backgroundImage:`url(${noticia.yoast_head_json.og_image[0].url ? noticia.yoast_head_json.og_image[0].url: null})`}}></div>
                                        </div>
                                        <div className="col-12 col-md-12 col-xl-8 ">
                                            <div className="card-body d-flex flex-column h-100 justify-content-center">
                                                <h5 className="card-title">{noticia.title.rendered.slice(0, 70)}</h5>
                                                <p className="card-text"><small className="text-muted">{parse(decodeEntities(new Date(noticia.date).toLocaleDateString('es-us', {weekday:"long", year:"numeric", month:"long", day:"numeric"})))}</small></p>
                                            </div>
                                        </div>
                                    </div>
                                    </a>
                                </article>
                            </div>
                        )
                    })}
                    </div>
                </div>
            :
            <div className='col-12 col-md-5 text-center pb-5 mb-5'>
                <h3>No hay noticias publicadas en este momento</h3>
            </div>
        )
    }

    return(
        <section className='row justify-content-center pb-5' style={{backgroundColor:'#F2F3F4'}} id={noticiasHome}>
            <div className='col-12 col-md-10'>
                <div className='row row-card justify-content-center align-items-center'>
                            {loading? (
                                <div className='col-12 col-md-5 text-center pb-5 mb-5'>
                                    <Spinner animation="border" role="status" variant="success">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                </div>
                            ):

                            renderNews(noticias)
                        }
                    </div>
                </div>
        </section>
    )
};


export default NoticiasList;