import React,{useEffect, useState} from 'react';
import { NavLink, useLocation } from "react-router-dom";
import Logo from '../../assets/images/logo.png';
import LogoWhite from '../../assets/images/logowhite.png';
import { Container, Navbar, Nav } from "react-bootstrap";

let MainNavigation = (props)=>{
    //console.log('nav props', useLocation());
    let location = useLocation();
    let [path,setPath] = useState(location.pathname);


    useEffect(()=>{
        setPath(location.pathname);
        // var navlnks = document.querySelectorAll(".nav-link a");
        // Array.prototype.map.call(navlnks, function(item) {

        //     item.addEventListener("click", function(e) {

        //         var navlnks = document.querySelectorAll(".nav-link a"); 

        //         Array.prototype.map.call(navlnks, function(item) {

        //             if (item.parentNode.className === "active" || item.parentNode.className === "active open" ) {

        //                 item.parentNode.className = "";

        //             } 

        //         }); 

        //         e.currentTarget.parentNode.className = "active";
        //     });
        // });
    },[location.pathname]);

    return(
        <header className={`${path ===  '/contactos' ? 'fixed-top-green' : 'fixed-top' } py-0`}>
            <Navbar collapseOnSelect expand="lg" className="p-0">
                <Container fluid className="gx-0 gx-lg-4 gb-5">
                    <Navbar.Brand href="/" className="me-0 ms-2 ms-md-0">
                        <img src={path ===  '/contactos' ? LogoWhite : Logo} alt='Logo'/>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarSupportedContent" className="me-2" />
                    <Navbar.Collapse id="navbarSupportedContent" className='justify-content-end'>
                        <Nav className="mb-2 mb-lg-0 pe-md-3">
                            <NavLink to="/" className={({ isActive, isPending }) =>
                                    isActive 
                                        ? "active nav-link"
                                        : isPending
                                        ? "pending nav-link"
                                        : "nav-link"
                                    }>Inicio</NavLink>
                            <NavLink to="/nosotros" className={({ isActive, isPending }) => 
                                    isActive
                                        ? "active nav-link"
                                        : isPending
                                        ? "pending nav-link"
                                        : "nav-link"
                                    }>Nosotros</NavLink>
                            <NavLink to="/productos-y-servicios" className={({ isActive, isPending }) =>
                                    isActive
                                        ? "active nav-link"
                                        : isPending
                                        ? "pending nav-link"
                                        : "nav-link"
                                    }>Productos y Servicios</NavLink>
                            <NavLink to="/noticias" className={({ isActive, isPending }) =>
                                    isActive
                                        ? "active nav-link"
                                        : isPending
                                        ? "pending nav-link"
                                        : "nav-link"
                                    }>Noticias</NavLink>
                            <NavLink to="/asociarse" className={({ isActive, isPending }) =>
                                    isActive
                                        ? "active nav-link"
                                        : isPending
                                        ? "pending nav-link"
                                        : "nav-link"
                                    }>Asociarse</NavLink>
                            <NavLink to="/contactos" className={({ isActive, isPending }) =>
                                    isActive
                                        ? "active nav-link"
                                        : isPending
                                        ? "pending nav-link"
                                        : "nav-link"
                                    }>Cont&aacute;ctenos</NavLink>
                        </Nav>
                            
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            {/* <nav className="navbar navbar-expand-lg bg-body-tertiary p-0">
                <div className="container-fluid gx-0 gx-lg-4 gb-5">
                    <NavLink className="navbar-brand ms-3" to={'/'}><img src={path ===  '/contactos' ? LogoWhite : Logo} alt='Logo'/></NavLink>
                    <button className="navbar-toggler me-3" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"  style={{borderColor: path ===  '/contactos' ? "rgba(255, 255, 255, 1)" : null}}>
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent" style={{backgroundColor: path ===  '/contactos' ? "rgba(42, 156, 99,0.9)" : null}}>
                        <ul className="navbar-nav mb-2 mb-lg-0 pe-md-3">
                            <li className="nav-item">
                                <NavLink
                                    to={'/'}
                                    className={({ isActive, isPending }) =>
                                    isActive
                                        ? "active nav-link"
                                        : isPending
                                        ? "pending nav-link"
                                        : "nav-link"
                                    }
                                >
                                    Inicio
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink
                                        to={`nosotros`}
                                        className={({ isActive, isPending }) =>
                                        isActive
                                            ? "active nav-link"
                                            : isPending
                                            ? "pending nav-link"
                                            : "nav-link"
                                        }
                                    >
                                    Nosotros
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink
                                        to={`productos-y-servicios`}
                                        className={({ isActive, isPending }) =>
                                        isActive
                                            ? "active nav-link"
                                            : isPending
                                            ? "pending nav-link"
                                            : "nav-link"
                                        }
                                    >
                                    Productos y Servicios
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink
                                        to={`noticias`}
                                        className={({ isActive, isPending }) =>
                                        isActive
                                            ? "active nav-link"
                                            : isPending
                                            ? "pending nav-link"
                                            : "nav-link"
                                        }
                                    >
                                    Noticias
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink
                                        to={`contactos`}
                                        className={({ isActive, isPending }) =>
                                        isActive
                                            ? "active nav-link"
                                            : isPending
                                            ? "pending nav-link"
                                            : "nav-link"
                                        }
                                    >
                                    Cont&aacute;ctenos
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink
                                        to={`asociarse`}
                                        className={({ isActive, isPending }) =>
                                        isActive
                                            ? "active nav-link"
                                            : isPending
                                            ? "pending nav-link"
                                            : "nav-link"
                                        }
                                    >
                                    Asociarse
                                </NavLink>
                            </li>
                        </ul>
                        <div className='socials d-flex'>

                        </div>
                    </div>
                </div>
            </nav> */}
        </header>
    );
}

export default MainNavigation;