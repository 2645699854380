import React,{memo} from 'react';
import Styles from './styles.module.css'

let Title = () => {
    let { title } = Styles;
    return (
        <section className='row justify-content-center align-items-center pb-5' id={title}>
            <article className='col-12 col-md-10 text-center py-5'>
                <h1>Productos y Servicios</h1>
            </article>
        </section>
    )
};

export default memo(Title);