import React,{memo, useEffect, useState, useRef} from  'react';
import parse from "html-react-parser";
import Spinner from 'react-bootstrap/Spinner';
import styles from './styles.module.css'

let NosotrosIntro = () => {
    let {nosotrosIntro, inner} = styles;
    let [nosotros, setNosotros] = useState([]);
    let [loading, setLoading] = useState(false);
    let query = useRef(true);

    useEffect(()=>{
        const nosotros = JSON.parse(localStorage.getItem('nosotros'));
        if(query.current && nosotros === null){
            query.current = false;
            setLoading(true);
            fetch('https://coopinmobilia.com/dashboard/wp-json/wp/v2/nosotros?status=publish&limit=1')
            .then((response) => response.json())
            .then(async(data)  => {
                localStorage.setItem('nosotros', JSON.stringify(data));
                setNosotros(data);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err.message);
                setLoading(false);
            });
        }else{
            setNosotros(nosotros);
            setLoading(false);
        }
    },[]);
    return(
        <section className='row justify-content-center align-items-center pb-5' id={nosotrosIntro}>
            <article className='col-12 col-md-7 text-center pt-5'>
                {loading ? (
                        <div className='row justify-content-center align-items-center'>
                            <div className='col-12 col-md-5 text-center pb-5 mb-5'>
                                <Spinner animation="border" role="status" variant="success">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </div>
                        </div>
                        ) :

                        nosotros.length === 0 ? ( 
                            <div className='row justify-content-center align-items-center'>
                                <div className='col-12 col-md-5 text-center pb-5 mb-5'>
                                    <h3>Sin info en este momento</h3>
                                </div>
                            </div>
                        ) : (
                            <>
                                <h1>{nosotros[0].title.rendered}</h1>
                                {parse(nosotros[0].content.rendered)}
                            </>
                            
                        )
                }
            </article>
            <article className='row justify-content-center align-items-center py-5'>
                <div className='col-12 col-md-10'>
                    <div className='row gx-2'>
                        <div className='col-12 col-md-4 text-center'>
                            <div className={`${inner} d-flex h-100 flex-column justify-content-start align-items-center py-5 px-4`}>
                            {loading ? (
                                <div className='row justify-content-center align-items-center'>
                                    <div className='col-12 col-md-5 text-center pb-5 mb-5'>
                                        <Spinner animation="border" role="status" variant="success">
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner>
                                    </div>
                                </div>
                                ) :
                                nosotros.length === 0 ? ( 
                                    <div className='row justify-content-center align-items-center'>
                                        <div className='col-12 col-md-5 text-center pb-5 mb-5'>
                                            <h3>Sin info en este momento</h3>
                                        </div>
                                    </div>
                                ) : (
                                <>
                                    <h3>{nosotros[0].acf.mision_titulo}</h3>
                                    <p>{parse(nosotros[0].acf.mision)}</p>
                                </>
                                )
                                }
                            </div>
                            
                        </div>
                        <div className='col-12 col-md-4 text-center my-3 my-md-0'>
                            <div className={`${inner} d-flex h-100 flex-column justify-content-start align-items-center py-5 px-4`}>
                            {loading && !nosotros ? (
                                <div className='row justify-content-center align-items-center'>
                                    <div className='col-12 col-md-5 text-center pb-5 mb-5'>
                                        <Spinner animation="border" role="status" variant="success">
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner>
                                    </div>
                                </div>
                                ) :
                                nosotros.length === 0 ? ( 
                                    <div className='row justify-content-center align-items-center'>
                                        <div className='col-12 col-md-5 text-center pb-5 mb-5'>
                                            <h3>Sin info en este momento</h3>
                                        </div>
                                    </div>
                                ) : (
                                <>
                                    <h3>{nosotros[0].acf.vision_titulo}</h3>
                                    <p>{parse(nosotros[0].acf.vision)}</p>
                                </>
                                )
                               
                                }
                            </div>
                        </div>
                        <div className='col-12 col-md-4 text-center'>
                            <div className={`${inner} d-flex h-100 flex-column justify-content-start align-items-center py-5 px-4`}>
                            {loading ? (
                                <div className='row justify-content-center align-items-center'>
                                    <div className='col-12 col-md-5 text-center pb-5 mb-5'>
                                        <Spinner animation="border" role="status" variant="success">
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner>
                                    </div>
                                </div>
                                ) :
                                nosotros.length === 0 ? ( 
                                    <div className='row justify-content-center align-items-center'>
                                        <div className='col-12 col-md-5 text-center pb-5 mb-5'>
                                            <h3>Sin info en este momento</h3>
                                        </div>
                                    </div>
                                ) : (
                                <>
                                    <h3>{nosotros[0].acf.valores_titulo}</h3>
                                    <p>{parse(nosotros[0].acf.valores)}</p>
                                </>
                                )
                               
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </article>
            <article className='row justify-content-center align-items-center py-3'>
                <div className='col-12 col-md-10 text-center'>
                    <h2>Estructura Organizacional Normativa</h2>
                </div>
            </article>
        </section>
    )
}

export default memo(NosotrosIntro);